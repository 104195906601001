<template>
  <v-container
    grid-list-sm
    class="mt-15"
  >
    <v-row class="pt-5">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <p class="mediumBlueMine OpenSansLight punt55Mine capLetterMine">
              {{ $t('landing.terms.title') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
              >
                {{ $t('landing.terms.body') }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle1') }}
            </p>
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle2') }}
            </p>

            <p>{{ $t('landing.terms.body2') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle3') }}
            </p>

            <p>{{ $t('landing.terms.body3') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle4') }}
            </p>

            <p>{{ $t('landing.terms.body4') }}</p>
            <p>{{ $t('landing.terms.body5') }}</p>
            <p>{{ $t('landing.terms.body6') }}</p>
            <p>{{ $t('landing.terms.body7') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle5') }}
            </p>

            <p>{{ $t('landing.terms.body8') }}</p>
            <p>{{ $t('landing.terms.body9') }}</p>
            <p>{{ $t('landing.terms.body10') }}</p>
            <p>{{ $t('landing.terms.body11') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle6') }}
            </p>

            <p>{{ $t('landing.terms.body12') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle7') }}
            </p>

            <p>{{ $t('landing.terms.body13') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle8') }}
            </p>

            <p>{{ $t('landing.terms.body14') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle9') }}
            </p>

            <p>{{ $t('landing.terms.body15') }}</p>
            <p>{{ $t('landing.terms.body16') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle10') }}
            </p>

            <p>{{ $t('landing.terms.body17') }}</p>
            <p>{{ $t('landing.terms.body18') }}</p>
          </v-col>

          <v-col cols="12">
            <p class="mediumBlueMine">
              {{ $t('landing.terms.subTitle11') }}
            </p>

            <p>{{ $t('landing.terms.body19') }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  setup() {
    return {
      urlRegister: `${process.env.VUE_APP_URL}register`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
    }
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      catalogs: [],
    }
  },
  methods: {
    redirectPage() {
      // window.open(this.urlRegister, '_blank')

      const route = this.$router.resolve({
        name: 'auth-register',
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.punt55Mine {
  font-size: 40px;
}

.punt55MineMov {
  font-size: 28px;
}

.punt32Mine {
  font-size: 20px;
}

.punt32MineMov {
  font-size: 20px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
